@font-face{font-family:'Mulish';src:url(./../fonts/mulish/Mulish-Regular.ttf) format("truetype");font-weight:400;font-style:normal}
@font-face{font-family:'MulishItalic';src:url(./../fonts/mulish/Mulish-Italic.ttf) format("truetype");font-weight:400;font-style:italic}
@font-face{font-family:'Mulish';src:url(./../fonts/mulish/Mulish-SemiBold.ttf) format("truetype");font-weight:500;font-style:normal}
@font-face{font-family:'MulishItalic';src:url(./../fonts/mulish/Mulish-SemiBoldItalic.ttf) format("truetype");font-weight:500;font-style:italic}
@font-face{font-family:'Mulish';src:url(./../fonts/mulish/Mulish-Bold.ttf) format("truetype");font-weight:600;font-style:normal}
body{font-family:"Mulish";font-size:1rem}
.container{
  width: 100%;
  max-width: 1320px;
}
*{box-sizing:border-box}
li{list-style:none}
.h1,.h2,.h3,.h4,.h5,.h6,h1,h2,h3,h4,h5,h6{font-weight:700}
.h-48{height:3rem}
.white-txt{color: #fff;}
.off-white{color:#FCFCFC}
.bg-off-white{background:#FCFCFC}
.bg-light-gray{background:#f0f0f0}
.bg-light-blue{background:#E9F5F9}
.bg-dark-blue{background-color:#202146}
.bg-blue{background-color:#30306F}
.bg-yellow{background-color:#FFCB66}
.bg-light-yellow{background-color:#FFF5E0}
.bg-light-green{background-color:#E2F8F7}
.font-dark{color: #191919;}

/*font-size*/
.f-14{font-size: 14px;}
.f-16{font-size: 16px !important;}
.f-18 { font-size: 18px;}
.f-24{font-size: 24px !important;}
.f-32{font-size: 2rem !important}
.f-22{font-size: 22px}
.bold{font-weight:700}
.semibold,.medium{font-weight:600}
.normal{font-weight:400}
.text-right {text-align: right;}
.navbar{height:5rem;position:sticky;top:0;z-index:3}
.navbar a{color:#fcfcfc;font-weight:700}
.nav-right a{padding-left:2rem}
.summary-block{padding:4rem 5rem;color:#fcfcfc}
ul li{list-style:none}
input,select{height:3.625rem}
.table>:not(:first-child){border-top:0 solid currentColor}
.search-box-container{width:100%;max-width:1280px;-webkit-box-pack:justify;justify-content:space-between;border-radius:8px;padding:5rem;margin-left:auto;margin-right:auto}
.search-box-inner-container{background-color:#e2f8f7;border-radius:8px;display:flex;-webkit-box-pack:justify;justify-content:space-between;width:100%}
.search-box-left-item{flex-grow:4}
.search-field-left{flex-grow:2}
.search-box-right-item{flex-grow:4;align-items:center;justify-content:flex-end;display:flex}
.title{font-size:1rem;font-weight:600;line-height:1.5rem;display:block}
.search-field-box{display:flex;-webkit-box-pack:justify;justify-content:space-between;align-items:flex-end}
.search-field-left label{font-style:normal;font-weight:600;line-height:2rem}
.search-field-left .inputContainer{width:100%;display:inline-block;width:100%;display:inline-block}
.search-box-left-item .inputContainer span{display:none}
.search-field-left .inputContainer input{width:100%;border:1px solid #c4c4c4;box-sizing:border-box;border-radius:8px;padding:.625rem 2.5rem}
.search-box-left-item .inputContainer input{font-size:1.125rem}
.search-box-left-item .inputContainer input.startAdornmentInput{text-indent:0;padding:1rem;background-color:#fcfcfc}
.search-box-container svg{max-width:300px;max-height:200px;width:100%;height:100%}
.defaultStyle{display:inline}
.imjbkB svg{width:auto;height:200px}
.search-box-container svg{max-width:300px;max-height:200px}
.sok-btn{cursor:pointer;user-select:none;border-radius:8px;text-decoration:none;box-sizing:border-box;line-height:2rem;background-color:#5cd6d2;color:#1c1c1c;border:3px solid #5cd6d2;transition:background-color 100ms ease-in-out 0s,color 100ms ease-in-out 0s,fill 100ms ease-in-out 0s,border-color 100ms ease-in-out 0;font-size:1.125rem;font-weight:700;display:flex;-webkit-box-align:center;align-items:center;-webkit-box-pack:center;justify-content:center;margin:0 0 0 1rem;padding:1rem 0;height:3.625rem;width:8.5rem}
.sok-btn:hover:not(:disabled){background-color:#48d1cc;border-color:#48d1cc}
.search-field-left .inputContainer input{border:2px solid #4A4E54}
.search-field-left .inputContainer input:focus{border:3px solid #191919}
[type=search]{outline-offset:0;-webkit-appearance:none}
.vlg-bil-manuelt-container{padding:5rem;max-width:1280px;margin:0 auto;border-radius:.625rem}
.search{position:relative;box-shadow:0 0 40px rgba(51,51,51,.1)}
.search input{height:60px;text-indent:25px;border:2px solid #d6d4d4}
.search input:focus{box-shadow:none;border:2px solid #252525}
.search .fa-search{position:absolute;top:20px;left:16px}
.search button{position:absolute;top:5px;right:5px;height:50px;width:110px}
i.fa.fa-search{position:absolute;z-index:9;top:17px;left:15px}
.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback){text-indent:30px;height:58px}
.btn-search{height:58px}
.btn{padding:.375rem 2.5rem;border-radius:.625rem;font-weight:600}
.form-control,.form-select{border:2px solid #4A4E54;height:58px ;box-sizing: border-box;border-radius: 8px;}
  .form-select{
    -webkit-appearance: none;
    -moz-appearance: window;
    background-size: 20px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxNiAxNic+PHBhdGggZmlsbD0nbm9uZScgc3Ryb2tlPScjMzQzYTQwJyBzdHJva2UtbGluZWNhcD0ncm91bmQnIHN0cm9rZS1saW5lam9pbj0ncm91bmQnIHN0cm9rZS13aWR0aD0nMicgZD0nTTIgNWw2IDYgNi02Jy8+PC9zdmc+);
    background-repeat: no-repeat;
    background-position: right 10px center;
    cursor: pointer;
  }
.form-control:focus,.form-select:focus{box-sizing: border-box;
  border-radius: 8px;color:#212529;background-color:#fff;border-color:#000;outline:0;box-shadow:inherit}
.tick-mark-container{display:flex;align-items:center;padding:.25rem;font-size:1.125rem}
#tick-mark{position:relative;display:inline-block;width:18px;height:18px;margin-right:1rem}
#tick-mark.tickmark-custom{background:transparent}
#tick-mark.tickmark-custom::before{position:absolute;left:0;top:10px;height:9px;width:2px;background-color:#66d9d5;content:"";transform:translateX(10px) rotate(-45deg);transform-origin:left bottom}
#tick-mark.tickmark-custom::after{position:absolute;left:1px;bottom:5px;height:2px;width:17px;background-color:#66d9d5;content:"";transform:translateX(10px) rotate(-45deg);transform-origin:left bottom}
#tick-mark{background:#66d9d5;border-radius:12px;position:relative;width:24px;height:24px}
#tick-mark::before{position:absolute;left:0;top:11px;height:6px;width:2px;background-color:#000;content:"";transform:translateX(10px) rotate(-45deg);transform-origin:left bottom}
#tick-mark::after{position:absolute;left:1px;bottom:7px;height:2px;width:10px;background-color:#000;content:"";transform:translateX(10px) rotate(-45deg);transform-origin:left bottom}
.nav-tabs .nav-item.show .nav-link,.nav-tabs .nav-link.active{color:#fcfcfc;background-color:transparent;border-color:inherit;border:none;border-bottom:3px solid #5CD6D2;padding:0}
.nav-tabs .nav-link{margin-bottom:-1px;background:0 0;border:1px solid transparent;border-top-left-radius:0;border-top-right-radius:0;color:#FCFCFC;font-size:1.125rem;font-weight:500;border-bottom:3px solid #30306F;margin-left:2rem;padding:0}
.nav-tabs{border-bottom:inherit;align-items: center;}
.value{font-size:2.5em;font-weight:600}
.title{font-weight:600}
.row-custom{display:flex;align-items:center;justify-content:space-between}
.comparison-table tr td:last-child,.comparison-table tr th:last-child,.comparison-table tr th:nth-child(2),.comparison-table tr td:nth-child(2){text-align:center}
[type="radio"]:checked,[type="radio"]:not(:checked){position:absolute;left:-9999px}
[type="radio"]:checked+label,[type="radio"]:not(:checked)+label{position:relative;padding-left:34px;cursor:pointer;display:inline-block;color:#000}
[type="radio"]:checked+label::before,[type="radio"]:not(:checked)+label::before{content:"";position:absolute;left:0;top:2px;width:18px;height:18px;border-radius:100%;background:#fff}
[type="radio"]:checked+label::before{border:2px solid #191919}
[type="radio"]:checked+label::after{opacity:1;transform:scale(1)}
[type="radio"]:checked+label::after,[type="radio"]:not(:checked)+label::after{content:"";width:10px;height:10px;background:#191919;position:absolute;top:6px;left:4px;border-radius:100%}
[type="radio"]:not(:checked)+label::before{border:2px solid #5d5d5d}
[type="radio"]:not(:checked)+label::after{opacity:0;transform:scale(0)}
.container-checkoBox{display:block;position:relative;padding-left:35px;cursor:pointer;font-size:1rem;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}
.container-checkoBox input{position:absolute;opacity:0;cursor:pointer;height:0;width:0}
.checkmark{position:absolute;top:0;left:0;height:22px;width:22px;border:2px solid #252525;border-radius:3px}
.container-checkoBox:hover input~.checkmark{background-color:#ccc}
.container-checkoBox input:checked~.checkmark{background-color:#252525}
.checkmark:after{content:"";position:absolute;display:none}
.container-checkoBox input:checked~.checkmark:after{display:block}
.total-block{border-bottom:1px solid #000;border-top:1px solid #000}
.text-container{display:flex;justify-content:space-between}
a.link-target{color:#fcfcfc;font-weight:500;text-decoration:none;border-bottom:2px solid #5CD6D2;padding-bottom:5px;font-size:1.25rem}
.card{border:1px solid rgba(0,0,0,0);border-radius:.5rem}
.card-body{padding:2rem}
.img-package-list{
  width: 100%;
  height: auto;
}
.img-container-border{
  border: 1px solid #E1E1E1;
  border-radius: 8px;
}
.card-footer{border-radius:0;background:none;padding:2rem}
.btn-link-grp{display:flex;justify-content:end;align-items:flex-end}
.btn-link-grp a{margin-right:1.5rem}
.form-group{margin:0}
.image-choose-container{display:flex;grid-gap:2rem;color:#000;flex-wrap:wrap}
label{display:flex;align-items:center;cursor:pointer}
.vh{position:absolute!important;clip:rect(1px,1px,1px,1px);padding:0!important;border:0!important;height:1px!important;width:1px!important;overflow:hidden}
.vh+label:before{content:'';width:20px;height:20px;border:2px solid rgba(8,8,8,1);display:inline-block;margin-right:10px;border-radius:4px}
input[type="checkbox"]:checked~label:before{background:#000 no-repeat center;background-size:12px 12px;background-image:url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDQ1LjcwMSA0NS43IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0NS43MDEgNDUuNzsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxnPgoJCTxwYXRoIGQ9Ik0yMC42ODcsMzguMzMyYy0yLjA3MiwyLjA3Mi01LjQzNCwyLjA3Mi03LjUwNSwwTDEuNTU0LDI2LjcwNGMtMi4wNzItMi4wNzEtMi4wNzItNS40MzMsMC03LjUwNCAgICBjMi4wNzEtMi4wNzIsNS40MzMtMi4wNzIsNy41MDUsMGw2LjkyOCw2LjkyN2MwLjUyMywwLjUyMiwxLjM3MiwwLjUyMiwxLjg5NiwwTDM2LjY0Miw3LjM2OGMyLjA3MS0yLjA3Miw1LjQzMy0yLjA3Miw3LjUwNSwwICAgIGMwLjk5NSwwLjk5NSwxLjU1NCwyLjM0NSwxLjU1NCwzLjc1MmMwLDEuNDA3LTAuNTU5LDIuNzU3LTEuNTU0LDMuNzUyTDIwLjY4NywzOC4zMzJ6IiBmaWxsPSIjRkZGRkZGIi8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==)}
input[type="checkbox"]:focus~label{color:#000}
tr td:last-child{text-align:right}
ol.collection{margin:0;padding:0}


.comparison-table-container{margin:0;flex-direction:column}
.comparison-table-container i.fa{font-size:1.5rem;position:relative}
.comparison-table-container .card-header{background:transparent}
.btn-check:focus+.btn,.btn:focus{outline:0;box-shadow:inherit}
.comparison-table-container tr td:last-child{text-align:inherit}
.close-icon{position:relative;right:0;width:24px;height:24px;opacity:1;left:0;text-align:center;background:#efefef;border-radius:12px}
.close-icon:before,.close-icon:after{position:absolute;left:0;content:' ';height:12px;width:2px;background-color:#333;right:0;margin:0 auto;top:6px}
.close-icon:before{transform:rotate(45deg)}
.close-icon:after{transform:rotate(-45deg)}
.comparison-table tr td{position:relative}
.expand-icon{height:24px;width:24px;position:relative;margin-right:.5rem}
.expand-icon::before,.expand-icon::after{content:" ";width:14px;height:3px;background-color:#000;display:block;position:absolute;top:50%;left:50%;transition:all .15s cubic-bezier(.42,0,.58,1);opacity:1;border-radius:2px}
.expand-icon::before{transform:translate(-50%,-50%) rotate(90deg)}
.expand-icon::after{transform:translate(-50%,-50%)}
.expand-icon.expanded::before{transform:translate(-50%,-50%) rotate(0deg)}
.expand-icon.expanded::after{transform:translate(-50%,-50%) rotate(0deg);opacity:0}
.add-btn{display:flex;border:#ffdead;font-weight:500}
.btn-grp{display:flex;justify-content:space-between;align-items:flex-end}
.modal-header{border:0}
.btn-fill-color{height:3.625rem;font-weight:600;display:inline-flex;-webkit-box-pack:justify;justify-content:space-between;-webkit-box-align:center;align-items:center;cursor:pointer;user-select:none;padding:1rem 1.5rem;border-radius:8px;text-decoration:none;box-sizing:border-box;background-color:#5cd6d2;color:#1c1c1c;border:3px solid #5cd6d2;transition:background-color 100ms ease-in-out 0s,color 100ms ease-in-out 0s,fill 100ms ease-in-out 0s,border-color 100ms ease-in-out 0}
.btn-fill-color:hover:not(:disabled){background-color:#48d1cc;border-color:#48d1cc}
.btn-fill-color:disabled{background-color:#e0e0e0;border-color:#c0c0c0;cursor: not-allowed;}
.btn-outline{display:inline-flex;-webkit-box-pack:justify;justify-content:center;-webkit-box-align:center;align-items:center;cursor:pointer;user-select:none;padding:1rem 1rem;border-radius:8px;text-decoration:none;box-sizing:border-box;border:3px solid #5cd6d2;transition:background-color 100ms ease-in-out 0s,color 100ms ease-in-out 0s,fill 100ms ease-in-out 0s,border-color 100ms ease-in-out 0;background-color:rgba(92,214,210,0);color:#1c1c1c;min-width:140px;height:3.625rem;font-weight:600}
.btn-outline:hover:not(:disabled){background-color:#48d1cc;color:#1c1c1c;border-color:#48d1cc}
.cta-btn-bottom-border{display:inline-flex;-webkit-box-pack:justify;justify-content:space-between;-webkit-box-align:center;align-items:center;cursor:pointer;user-select:none;padding:.625rem;text-decoration:none;box-sizing:border-box;line-height:2rem;background-color:rgba(92,214,210,0);color:#1c1c1c;border-top:none #48d1cc;border-right:none #48d1cc;border-left:none #48d1cc;border-image:initial;border-bottom:3px solid #48d1cc;border-radius:0;transition:border-width 100ms ease-in-out 0s,padding-bottom 100ms ease-in-out 0;font-size:1.125rem}
.right-side-modal .backdrop{-webkit-box-align:center;align-items:center;background-color:rgba(0,0,0,0.6);display:flex;height:100vh;-webkit-box-pack:center;justify-content:center;left:0;min-height:100vh;position:fixed;top:0;width:100vw}
.right-side-modal .next-modal{box-shadow:#000 3px 3px 15px -3px;background-color:#f3f3f3;overflow:auto;padding:2rem;z-index:10001;width:26.5rem;position:absolute;right:0;top:0;bottom:0;max-height:calc(100vh - 0px)}
.popup-close{display:inline-flex;-webkit-box-pack:justify;justify-content:space-between;-webkit-box-align:center;align-items:center;cursor:pointer;user-select:none;border-radius:8px;text-decoration:none;box-sizing:border-box;line-height:2rem;padding:1.25rem;border:3px solid #b1b1b1;transition:background-color 0 ease-in-out 0s,color 0 ease-in-out 0s,fill 0 ease-in-out 0s,border-color 0 ease-in-out 0;background-color:rgba(177,177,177,0);color:#191919;height:3rem}
.popup-close:hover:not(:disabled){background-color:#a4a4a4;color:#fcfcfc;border-color:#a4a4a4}
.popup-close:hover:not(:disabled) svg{fill:#fcfcfc}
.right-side-modal .next-modal .header{margin-bottom:1.875rem;font-size:1.375rem}
.inputContainer textarea,.inputContainer input{width:100%;border:2px solid #4a4e54;box-sizing:border-box;border-radius:8px;padding:1rem;font-size:1.125rem}
.htClhd .inputContainer .inputAndIconContainer input{border-radius:8px 0 0 8px;border-width:2px 1px 2px 2px}
.inputContainer .inputAndIconContainer{position:relative}
.htClhd .dropDownContainer{min-width:3.5rem;width:5rem;padding-top:0;font-size:1.125rem}
.css-1cscsue-control{-webkit-box-align:center;align-items:center;background-color:#fff;border-radius:8px;cursor:pointer;display:flex;flex-wrap:wrap;-webkit-box-pack:justify;justify-content:space-between;min-height:48px;position:relative;transition:all 100ms ease 0;box-sizing:border-box;border:2px solid #4a4e54;height:3.625rem;max-height:3.625rem;outline:0!important}
.htClhd .dropDownContainer #inputDropDown div{border-radius:0 8px 8px 0;border-width:2px 2px 2px 1px}
.css-1aagyzh{-webkit-box-align:center;align-items:center;display:flex;flex:1 1 0;flex-wrap:wrap;padding:0 .5rem;position:relative;overflow:unset;box-sizing:border-box;max-height:3.625rem;line-height:1.6rem}
.singleValue{color:#333;margin-left:2px;margin-right:2px;max-width:calc(100% - 8px);overflow:hidden;position:absolute;text-overflow:ellipsis;white-space:nowrap;top:50%;transform:translateY(-50%);box-sizing:border-box;width:100%;padding-right:2rem}
.dummyInput{background:0 center;border:0;font-size:inherit;outline:0;padding:0;width:1px;color:transparent;left:-100px;opacity:0;position:relative;transform:scale(0)}
.kBoqHt{width:100%;height:100%}
#indicator{margin-top:-7px}
.indicatorContainer{color:#ccc;display:flex;padding:8px;transition:color 150ms ease 0;box-sizing:border-box}
.inputContainer .inputAndIconContainer{position:relative}
.adornment-container{position:absolute;top:1.1rem;overflow:hidden;text-align:left;left:.8rem}
.inputContainer input.startAdornmentInput{text-indent:30px}
.search-icon-right{position:absolute;top:1rem;right:1rem}
.custom-radio-grp{display:flex;column-gap:2rem;margin-bottom:10px}
.single-textbox{display:grid;grid-template-columns:1fr 5fr;column-gap:2rem}
.headerText{color:#1c1c1c;font-size:1.375rem}
.client-info{color:#B1B1B1}
.highlighttext{font-size:1.25rem;font-weight:600}
.available-deals.cards{display:flex;flex-wrap:wrap ;gap:1rem;}
.available-deals.cards .card{flex:1 0 20%;position:relative;border:2px solid #f0f0f0}
/* .available-deals.cards .active{border-color:#000} */
.available-deals.cards .text-container{justify-content:flex-start;flex-direction:column;color:#4A4E54}
.available-deals.cards .text-container .lable{margin-right:5px;font-size:.875rem;font-weight:600;color:#4A4E54}
.available-deals.cards .text-container .value-text{font-size:1.125rem;font-weight:600 ;line-height: 1.2;}

.inputArea-data {
    background-color: white;
    border: 3px solid #191919;
    border-radius: 10px;
    box-sizing: border-box;
}

.customer-results {
    max-height: 50vh;
    overflow-y: auto;
    color: #1c1c1c;
}
.customer-container:nth-child(odd) { background-color: #e3e3e3;}
.b-0{border: 0px !important;}

/* #even {
    background-color: #e3e3e3;
} */

.customer-results .customer-container {
    display: grid;
    cursor: pointer;
    padding: 0.5rem 1rem;
}
.customer-results .customer-container > div {
    padding: 0.35rem 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.customer-container-footer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem 2rem;
    border: 2px #dfdfdf;
    border-style: solid none none none;
    place-items: center;
}

.ico-16 {
  width: 16px;
  height: 16px;
  background-image: url(/assets/images/icons_16.png);
  background-repeat: no-repeat;
  display: block;
  float: left;
}
.copy-btn{
  position: relative;
  top: 3px;
  left: 10px;
}
.copy-success-icon-tooltip {
  background-position:-174px -402px;
}
.copy-white-icon {
  background-position: -40px -399px;
}
.copy-white-icon:hover, .copy-white-icon:focus, .copy-white-icon:active, .copy-icon:hover, .copy-icon:focus, .copy-icon:active {
  background-position:-138px -402px;
  transform: scale(1.3);
}
.dIvqhR label {
    margin-left:5px;
}
.dIvqhR input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    margin-bottom: 0px;
    height: 36px;
    width: 36px;
}
.dIvqhR .state {
    border: 2px solid transparent;
    border-radius: 36px;
    height: 36px;
    width: 36px;
}
.dIvqhR .state label {
    display: none;
}

.available-deals.cards input[type=checkbox] ~ .state {
    display: none;
}
.available-deals.cards input[type=checkbox]:checked ~ .state {
    display: block;
}
.available-deals.cards .card.active .btn-outline{background: #48d1cc;}
.radio-btn-custome input[type=radio] + .btn-outline {
       position: relative;
    padding-left: 0;
    cursor: pointer;
    display: flex;
    color: #000;}
.radio-btn-custome input[type=radio]:checked + .btn-outline {
    background: #48d1cc;
    position: relative;
    padding-left: 0;
    cursor: pointer;
    display: flex;
    color: #000;}
.radio-btn-custome input[type=radio] + .btn-outline span {display: none;}
.radio-btn-custome input[type=radio]:checked + .btn-outline span {display: block;}
.radio-btn-custome input[type="radio"]:checked+label::before, .radio-btn-custome input[type="radio"]:not(:checked)+label::before{opacity: 0;display:none;}
.radio-btn-custome input[type="radio"]:checked+label::after, .radio-btn-custome input[type="radio"]:not(:checked)+label::after {
  display: none;
}

.lead-text{text-align: justify; font-size: 22px; line-height: 35px ; letter-spacing: 0.01em; color: #FCFCFC;opacity: 0.7;}
.has-search .form-control {
    padding-left: 2.375rem;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height:58px;
    text-align: center;
    cursor: pointer;

}
.filter-checkbox-container{display:flex;grid-gap:1rem;color:#000;flex-wrap:wrap; flex-direction: column;}
.yellw-tag{
    align-items: flex-start;
    padding: 4px 16px;
    width: auto;
    height: 26px;
    background: #FFCB66;
    border-radius: 23px;
    color: #191919;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 1rem;
}
.short-text{
    line-height: 130%;
}
.cta-btn-bottom-border {
font-weight: 700;
font-size: 18px;
line-height:  18px;
letter-spacing: 0.02em;
}
.elips-green{width: 8px;
    height: 8px;
    background: #62C785;
    border-radius: 8px;
    display: inline-flex;
    margin-right: 0.5rem
}
.mild-red{width: 8px;
  height: 8px;
  background: #EB5B4F;
  border-radius: 8px;
  display: inline-flex;
  margin-right: 0.5rem
}
.mild-yellow{width: 8px;
  height: 8px;
  background: #FFCB66;
  border-radius: 8px;
  display: inline-flex;
  margin-right: 0.5rem
}
.available-deals.cards .card.selected,.available-deals.cards .card:hover {
    background: #fff;
    border: 2px solid #5CD6D2;
    cursor: pointer;
}

/*Media Query*/
@media screen and (max-width: 736px) {
    .collection-container{display:grid;grid-template-columns:1fr 1fr;grid-gap:20px}
    .item{border:1px solid gray;border-radius:2px;padding:10px}
    .collection-container>li:first-child{display:none}
    .attribute::before{content:attr(data-name)}
    .attribute{display:grid;grid-template-columns:minmax(9em,30%) 1fr}
    .custome-radio-buttion{position:relative;display:flex;justify-content:start;align-items:center}
}
@media screen and (max-width:580px) {
    .collection-container{display:grid;grid-template-columns:1fr}
    .custome-radio-buttion{position:relative;display:flex;justify-content:start;align-items:center}
}
@media screen and (min-width: 737px) {
    .item-container{display:grid;grid-template-columns:2fr 2fr 2fr 2fr 1fr 1fr}
    .attribute-container{display:grid;grid-template-columns:repeat(auto-fit,minmax(var(--column-width-min),1fr))}
    .part-information{--column-width-min:10em}
    .part-id{--column-width-min:10em}
    .vendor-information{--column-width-min:8em}
    .quantity{--column-width-min:5em}
    .cost{--column-width-min:5em}
    .duty{--column-width-min:5em}
    .freight{--column-width-min:5em}
    .attribute{border-bottom:1px solid #f3f3f3;padding:2px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;padding:.625rem;font-weight:600}
    .item-container>.attribute:last-child{text-align:right}
    .item-container:hover{background-color:#c8e3fc}
    .collection-container>.item-container:first-child .attribute{display:flex;align-items:center;justify-content:start;text-overflow:initial;overflow:auto;white-space:normal;border-bottom:1px solid #f3f3f3;background:#F3F3F3}
    .custome-radio-buttion{position:relative;display:flex;justify-content:center;align-items:center}
    .item-container.search-customer-list{display:grid;grid-template-columns:2fr 2fr 2fr 8em}
}

@media (pointer: coarse) {
    .available-deals.cards input[type=checkbox]{height:2em;width:2em}
}
@media (hover: hover) {
    .available-deals.cards input[type=checkbox]{z-index:-1}
    .available-deals.cards .card:hover input[type=checkbox],.available-deals.cards input[type=checkbox]:focus,.available-deals.cards input[type=checkbox]:checked{z-index:auto}
}
* {box-sizing:border-box}

/* Slideshow container */
.slideshow-container {
  max-width: 600px;
  position: relative;
  margin: auto;
}
.slideshow-container .fade:not(.show) {
    opacity: initial;
}
/* Make the images invisible by default */
.Containers {
  display: none;
}

/* forward & Back buttons */
.Back, .forward {
  cursor: pointer;
  position: absolute;
  top: 260px;
  width: auto;
  margin-top: -23px;
  padding: 5px;
  color: grey;
  font-weight: bold;
  font-size: 19px;
  transition: 0.4s ease;
  border-radius: 0 5px 5px 0;
  user-select: none;
}

/* Place the "forward button" to the right */
.forward {
  right: 0;
  border-radius: 4px 0 0 4px;
}

/*when the user hovers,add a black background with some little opacity */
.Back:hover, .forward:hover {
  transform: scale(1.1);
}

/* Caption Info */
.Info {
  color: #e3e3e3;
  font-size: 16px;
  padding: 10px 14px;
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center;
}

/* Worknumbering (1/3 etc) */
.MessageInfo {
  color: #f2f2f3;
  font-size: 14px;
  padding: 10px 14px;
  position: absolute;
  top: 0;
}

/* The circles or bullets and indicators */
.dots {
  cursor: pointer;
  height: 8px;
  width: 8px;
  margin: 0 3px;
  background-color: #bfbfbf;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.5s ease;
}

.enable, .dots:hover {
  background-color: #000000;
}

/* Faint animation */
/* .fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.4s;
  animation-name: fade;
  animation-duration: 1.4s;
} */

@-webkit-keyframes fade {
  from {opacity: .5}
  to {opacity: 2}
}

@keyframes fade {
  from {opacity: .5}
  to {opacity: 2}
}
.inner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5rem 0;
}
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #fff;
  border-radius: 0.25rem;
}
.breadcrumb-item+.breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: '';
  background-image: url(/assets/images/Arrows-right.svg);
  width: 20px;
  height: 20px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.breadcrumb li a{
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-decoration-line: underline;
  color: #191919;
  margin: 0px 16px;
}
.breadcrumb-item.active span {
  color: #191919;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  margin: 0px 16px;
}
.number span {cursor:pointer; }
		.number{
      display: flex;
      justify-content: center;
      align-items: center;
		}
		.number input{
      width: 43px;
      height: 45px;
      background: #FFFFFF;
      border: 2px solid #B1B1B1;
      box-sizing: border-box;
      border-radius: 8px;
      font-weight: 600;
      font-size: 18px;
      line-height: 160%;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.02em;
      color: #191919;
    }

    .table-row {
      background: #f3f3f3;
      border-radius: 8px;
  }
  .table-header,  .table-row {
    display: grid;
    grid-template-columns: 9rem 28rem 2fr 2fr 2fr 2fr 3rem;
    gap: 10px;
    align-items: center;
}
.sub-table{
  font-weight: 600;
  font-size: .875rem;
  line-height: 1.125rem;
  color: #4A4E54;
}
.cart-counter{
  width: 18px;height: 18px;border-radius: 100%;
  background: #FFCB66;position: absolute;
  font-size: 12px;font-weight: 600;right: -8px;bottom: -8px;}
.table-header{padding:1rem 1.5rem;}
.mt-35{margin-top: 3px;}
.mt-22{margin-top: 2px;}
.min-wid-50{min-width: 50px;}
.min-wid-80{min-width: 80px;}
.cursor-pointer{cursor: pointer;}
.ml-3{margin-left:1rem ;}
.detail-row-header, .detail-row-container {
  display: grid;
  grid-template-columns:12rem 14rem 2fr 2fr 10rem 9rem;
  gap: 10px;
  padding:.5rem 0rem;
  align-items: center;
}

.cart-detail-row-header, .cart-detail-row-container {
  display: grid;
  grid-template-columns:14% 24% 14% 12% 12% 12% 12%;
  padding:.5rem 0rem;
  align-items: center;
}

  .table-detail-row-container {
    background: #f3f3f3;
    border-radius: 8px;
    padding: 1rem 1.5rem;
}
.detail-row {
  /* display: none; */
  border-top: 1px solid #ccc;
}

.innhold.accordion-button {
  width: auto;
  padding: 0;
  background: none;
  border: 0;
  text-decoration: none;
  outline: 0;
  cursor: pointer;

}
.innhold.accordion-button:not(.collapsed) {
  color: #191919;
  background-color: #f3f3f3;
  box-shadow: none;
}
.table-detail-row-container .accordion-body {
  padding: 1rem 0rem;
}
.accordion-item.table-detail-row-container.my-2 {
  border: 0;
}
.innhold.accordion-button::after {
  width: 1rem;
  height: 1rem;
  margin-left: 10px;
  background-size: 1rem;
}
.innhold.accordion-button:not(.collapsed)::after {
  background-image: black;
  transform: rotate(-180deg);
}
.btn-lightgreen-outline {
  background: #E2F8F7;
  border: 1px solid #5CD6D2;
  box-sizing: border-box;
  border-radius: 8px;
}
.nav-tabs .cart-button{
  border:none;
  margin-left: 0;
}
.btn-disabled {
  cursor:not-allowed;
}
.cursor-pointer{
  cursor: pointer
}
.dashbord-dropdown{
    position: relative;
    display: inline-block;
    z-index: 0%!important;
}
.dashbord-dropdown select.form-select {
  background: transparent;
  border: 0;
  border-bottom: 3px solid #5CD6D2;
  border-radius: 0;
  padding-right: 36px;
  font-weight: 600;
  font-size: 1.125rem;
  position: relative;
  z-index: 1;
  cursor: pointer;
}
.custom-table tr td{
  text-align: left;
}
.innhold.accordion-button{
  margin-top:17px;
}
.center-modal .next-modal {
  box-shadow: #000 3px 3px 15px -3px;
  background-color: #f3f3f3;
  overflow: auto;
  padding: 2rem;
  z-index: 10001;
  width: 26.5rem;
  position: absolute;
  /* right: 0; */
  top: 25%;
  /* bottom: 0; */
  /* max-height: calc(100vh - 0px) */
}
.right-side-modal .next-modal .header, .center-modal .next-modal .header {
  margin-bottom: 1.875rem;
  font-size: 1.375rem
}

.backdrop {
  align-items: center;
  background-color: #0009;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  min-height: 100vh;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 999;
}
.backdrop-internal {
  align-items: center;
  background-color: #0009;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  min-height: 100vh;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 99999;
}
.txt-end{
  text-align: end;
}
.color-red,.mand{
  color: red;
}
.set-dealerName{
  position: absolute;
    left: 65px;
    top: 16px;
}
.set-underline{
    border-bottom: 1px solid black;
    width: 31px;
    position: absolute;
    top: 42px;
    left: 20px;
}
.popover{
  max-width: 296px !important;
}
.modal-content{
  border: none !important;
}